
















import GenericForm from "@/components/forms/GenericForm.vue";
import CustomerUsersTable from "@/components/tables/CustomerUsersTable.vue";

import { Store } from "@/store/store";
import { Component } from "vue-property-decorator";

interface CustomerUsers {
  activated_at?: string;
  addons?: Array<any>;
  billing_plan?: any;
  created_at?: string;
  email?: string;
  id?: number;
  is_pending?: boolean;
  last_name?: string;
  meeting_room_alias?: string;
  name?: string;
  role?: string;
}

@Component({
  components: {
    GenericForm,
    CustomerUsersTable,
  },
})
export default class CustomerUsersComponent extends Store {
  componentTitle = "Customer Users";

  customerID = "";

  trial = {
    trial_extended: false,
    trial_ends_at: "",
    trial_expired_at: "",
  };

  customerUsers: Array<CustomerUsers> = [];

  trialsErrorMessage = "";
  trialsSuccessMessage = "";
  isTrial = false;

  // Computed
  get isDisabled() {
    return this.isReseller && this.isTrial ? false : true;
  }

  created() {
    this.customerID = this.$route.params.id;
  }

  async mounted() {
    if (this.customerID) {
      await this.refresh();
    }
  }

  async refresh() {
    if (this.customerID) {
      const companyAccount = await this.getCompanyAccount(this.customerID);

      if (companyAccount) {
        this.isTrial = companyAccount.is_trial;
        // trial details
        this.trial.trial_extended = companyAccount.extended_trial;
        this.trial.trial_ends_at = companyAccount.trial_ends_at;
        this.trial.trial_expired_at = companyAccount.trial_expired_at;
        // Save users
        this.customerUsers = (companyAccount.users as unknown) as CustomerUsers[];
      }
    }
  }
}
















interface Tabs {
  id: string,
  title: string,
}

// @vuese
// @group Forms
// Generic Form

import Component from "vue-class-component";
import { Prop, Emit } from 'vue-property-decorator';
import Vue from "vue";

@Component
/**
 * Generates Section Header Tabs
 */
export default class GenericForm extends Vue {
  @Prop() readonly tabs: Array<Tabs> | undefined
  @Prop() readonly currentTab: string | undefined

  /* tslint:disable:no-empty */
  /**
   * Fire when the form is submitted
   */
  @Emit()
  selected(newTab: string) {
    return newTab
  }

  isCurrentTab(thisTab: string): boolean {
    return thisTab === this.currentTab
  }
}

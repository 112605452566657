




















import { Component, Prop, Emit } from "vue-property-decorator";
import { Store } from "@/store/store";
import TableLoading from "./TableLoading.vue";

interface CustomerUsers {
  activated_at?: string;
  addons?: Array<any>;
  billing_plan?: any;
  created_at?: string;
  email?: string;
  id?: number;
  is_pending?: boolean;
  last_name?: string;
  meeting_room_alias?: string;
  name?: string;
  role?: string;
  length?: number;
}

@Component({
  components: {
    TableLoading,
  },
})
export default class CustomerUsersTable extends Store {
  componentTitle = "Customer Users Table";

  @Prop({
    default() {
      return [];
    },
  })
  items!: Array<CustomerUsers>;

  @Prop({
    default() {
      return {};
    },
  })
  trial!: {};

  /* tslint:disable:no-empty */
  /**
   * Fired when the user triigers an action
   * @vuese
   */

  @Emit()
  refresh() {}

  get fields() {
    return [
      { key: "name", label: "Name" },
      { key: "email", label: "Email" },
      { key: "is_remote_expert", label: "Remote Expert" },
      { key: "is_phonebook", label: "Phonebook" },
      { key: "phone", label: "Phone" },
      { key: "meeting_room_alias", label: "Room Id" },
      { key: "licence", label: "Licence" },
      { key: "addons", label: "Addons" },
      { key: "role", label: "Role" },
      { key: "manage", label: "" },
    ];
  }

  get itemsExtended() {
    if (this.trial) {
      const output: any = [];

      this.items?.forEach((user) => {
        output.push({
          ...user,
          licence: user.billing_plan?.name,
          addons: user.addons?.map((addon) => addon.name).join(", "),
        });
      });

      return output;
    } else {
      return this.items;
    }
  }

  loginAsUser(id: number) {
    this.setUserCache({ setUserCache: { userId: id } })
      .then((res: any) => 
        res.data?.url
          ? window.open(res.data.url)
          : this.$toast.error('Redirect url is not found')
      )
      .catch(() => this.$toast.error('Login failed'));
  }
}

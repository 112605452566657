




























import SectionTabs from "@/components/SectionTabs.vue";

import { Home } from "@/router";

import Component from "vue-class-component";
import { Store } from "@/store/store";

import AccountDetails from "../components/account_details.vue";
import BusinessBranding from "../components/business_branding.vue";
import TrialUsers from "../components/trial_users.vue";
import CustomerUsers from "../components/customer_users.vue";

interface Tabs {
  id: string;
  title: string;
}

@Component({
  components: {
    SectionTabs,
    AccountDetails,
    BusinessBranding,
    TrialUsers,
    CustomerUsers,
  },
  metaInfo: {
    title: "Edit Customer Account"
  }
})
export default class EditAccount extends Store {

  tabs: Array<Tabs> = [
    {
      id: "account_details",
      title: "Customer Details"
    },
    {
      id: "customer_users",
      title: "Users"
    },
    {
      id: "branding",
      title: "Business Branding"
    },
    {
      id: "trial_licenses",
      title: "Trial Licenses"
    },
  ];

  currentTab = this.tabs[0].id;
  
  Home = Home;

  get componentTitle() {
    return this.isReseller ? "Edit Customer Account" : "View Customer Account"
  }

  showTab(showTab: string) {
    this.currentTab = showTab;
  }

  isCurrentTab(thisTab: string): boolean {
    return thisTab === this.currentTab;
  }  
}
